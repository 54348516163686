<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 class="titulo">
          Recursos para docentes de Secundaria
        </h2>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2 class="subtitulo">Cronodosificación secundaria 2022-2023</h2>
      </div>
      <div class="col-12">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Descripción</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>CRONODOSIFICACIÓN 1° SECUNDARIA</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PRONI/high-school/cronodosificacion/CRONODOSIFICACIÓN 1° SECUNDARIA.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>CRONODOSIFICACIÓN 2° SECUNDARIA</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PRONI/high-school/cronodosificacion/CRONODOSIFICACIÓN 2° SECUNDARIA.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>CRONODOSIFICACIÓN 3° SECUNDARIA</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PRONI/high-school/cronodosificacion/CRONODOSIFICACIÓN 3° SECUNDARIA.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2 class="subtitulo">Planeación de clases</h2>
        <p>
          La planeación de clase es una invaluable herramienta para el exitoso
          desarrollo de la misma en un contexto donde se promuevan y garanticen
          los aprendizajes esperados de los estudiantes. Por ello, el saber cómo
          planear correctamente tiene gran relevancia para nuestra Coordinación.
        </p>
        <p>
          A fin de apoyar a los docentes que este ciclo escolar tienen asignados
          grados que no habían impartido antes, nos permitimos compartir los
          siguientes SAMPLE LESSON PLANS para los distintos grados de educación
          secundaria.
        </p>
      </div>
      <div class="col-12">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Descripción</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>LESSON PLAN FORMAT 1° SECUNDARIA</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PRONI/high-school/lesson-plans/LESSON PLAN FORMAT 1° SECUNDARIA.doc" target="_blank">
                  <span class="iconify" data-icon="mdi:microsoft-word" width="35" height="35" color="blue"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>LESSON PLAN FORMAT 2° SECUNDARIA</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PRONI/high-school/lesson-plans/LESSON PLAN FORMAT 2° SECUNDARIA.doc" target="_blank">
                  <span class="iconify" data-icon="mdi:microsoft-word" width="35" height="35" color="blue"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>LESSON PLAN FORMAT 3° SECUNDARIA</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PRONI/high-school/lesson-plans/LESSON PLAN FORMAT 3° SECUNDARIA.doc" target="_blank">
                  <span class="iconify" data-icon="mdi:microsoft-word" width="35" height="35" color="blue"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>LESSON PLAN EXPLAIN SECUNDARIA</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PRONI/high-school/lesson-plans/LESSON PLAN EXPLAIN SECUNDARIA.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-12">
        <div class="banner_sm bg_dg-4 mb-3">
          <div class="item1">
            <p class="titulo">Inglés en secundaria</p>
          </div>
          <div class="item2">
            <p class="contenido">Sugerencias para la planeación</p>
          </div>
          <div class="item3">
            <a href="https://sites.google.com/iea.edu.mx/inglesensecundaria2022/inicio" target="_blank" class="btn btn-principal-light">
              Ir al sitio
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h2 class="subtitulo">Materiales sugeridos para integrar en la planeación de clases</h2>
      </div>
      <div class="col-12">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Descripción</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>GREETING STUDENTS, SETTING THE DATE AND CREATING AND ENGLISH ATMOSPHERE IN THE CLASSROOM</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PRONI/high-school/resources/1-GREETING STUDENTS, SETTING THE DATE AND CREATING AND ENGLISH ATMOSPHERE IN THE CLASSROOM.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>REVIEWING PRIOR KNOWLEDGE</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PRONI/high-school/resources/2-REVIEWING PRIOR KNOWLEDGE.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>SETTING THE CLASS OBJECTIVES AND ACTIVATING PRIOR KNOWLEDGE</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PRONI/high-school/resources/3-SETTING THE CLASS OBJECTIVES AND ACTIVATING PRIOR KNOWLEDGE.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>INTRODUCING NEW OR RECYCLED VOCABULARY</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PRONI/high-school/resources/4-INTRODUCING NEW OR RECYCLED VOCABULARY.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>PRESENTING KEY LANGUAGE IN CONTEXT</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PRONI/high-school/resources/5-PRESENTING KEY LANGUAGE IN CONTEXT.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>6</td>
              <td>CHECKING COMPREHENSION OF KEY LANGUAGE</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PRONI/high-school/resources/6-CHECKING COMPREHENSION OF KEY LANGUAGE.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>7</td>
              <td>PROVIDING OPPORTUNITIES TO USE THE KEY LANGUAGE</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PRONI/high-school/resources/7-PROVIDING OPPORTUNITIES TO USE THE KEY LANGUAGE.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>8</td>
              <td>MONITORING AND HELPING LEARNERS IF NEEDED</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PRONI/high-school/resources/8-MONITORING AND HELPING LEARNERS IF NEEDED.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>9</td>
              <td>MONITORING AND ASSESSING STUDENTS LEARNING</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PRONI/high-school/resources/9-MONITORING AND ASSESSING STUDENTS LEARNING.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>10</td>
              <td>WRAPPING UP THE CLASS</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/niveles-educativos/educacion-basica/relacion-interinstitucional-y-social/programas-educativos/PRONI/high-school/resources/10-WRAPPING UP THE CLASS.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PRONISecundariaView"
}
</script>

<style>

</style>

<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="titulo">IEA - Informes del Portal Aplicativo de la Secretaría de Hacienda (PASH)</h1>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Periodo 2018</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Informe</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Primer trimestre</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/PASH/2018/PASH2018_1q.xlsx" target="_blank">
                  <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>Segundo trimestre</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/PASH/2018/PASH2018_2q.xlsx" target="_blank">
                  <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Periodo 2017</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Informe</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Primer trimestre</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/PASH/2017/PASH 2017 1q.xlsx" target="_blank">
                  <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>Segundo trimestre</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/PASH/2017/PASH 2017 2q.xlsx" target="_blank">
                  <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>Tercer trimestre</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/PASH/2017/PASH 2017 3q.xlsx" target="_blank">
                  <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>Cuarto trimestre</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/PASH/2017/PASH 2017 4q.xls" target="_blank">
                  <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>Cuarto trimestre (Definitivo)</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/PASH/2017/T4_2017_DEFINITIVO_PASH.xlsx" target="_blank">
                  <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InformesPASHView"
}
</script>

<style>

</style>

<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="titulo">IEA - Informes del Sistema de Recursos Federales Transferidos (SRFT)</h1>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Fondo de Aportaciones para la Nómina Educativa (FONE)</h2>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Ejercicio Fiscal 2024</h2>
        <table class="table">
          <thead>
            <tr>
              <th>Tipo</th>
              <th>Informe</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Periódico Oficial del Estado</td>
              <td colspan="2">
                <table class="table table-striped table-hover">
                  <tbody>
                    <tr>
                      <td>Primer trimestre</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2024/1T_2024 POE.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Segundo trimestre</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2024/2T_2024 POE.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>Ejercicio del gasto</td>
              <td colspan="2">
                <table class="table table-striped table-hover">
                  <tbody>
                    <tr>
                      <td>Primer trimestre. Ejercicio del gasto FONE</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2024/1T_2024 AGS Ejercicio del gasto FONE.xlsx" target="_blank">
                          <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Segundo trimestre. Ejercicio del gasto FONE</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2024/2T_2024 AGS Ejercicio del gasto FONE.xlsx" target="_blank">
                          <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>Indicadores</td>
              <td colspan="2">
                <table class="table table-striped table-hover">
                  <tbody>
                    <!-- <tr>
                      <td>Cuarto trimestre. Indicadores FONE</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2023/4T_2023 Indicadores FONE.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                      </td>
                    </tr> -->
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Ejercicio Fiscal 2023</h2>
        <table class="table">
          <thead>
            <tr>
              <th>Tipo</th>
              <th>Informe</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Periódico Oficial del Estado</td>
              <td colspan="2">
                <table class="table table-striped table-hover">
                  <tbody>
                    <tr>
                      <td>Primer trimestre</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2023/1T_2023 POE.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Segundo trimestre</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2023/2T_2023 POE.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Tercer trimestre</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2023/3T_2023 POE.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Cuarto trimestre</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2023/4T_2023 POE.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Informe definitivo</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2023/ID_2023 POE.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>Ejercicio del gasto</td>
              <td colspan="2">
                <table class="table table-striped table-hover">
                  <tbody>
                    <tr>
                      <td>Primer trimestre. Ejercicio del gasto FONE</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2023/1T_2023 AGS Ejercicio del gasto FONE.xlsx" target="_blank">
                          <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Segundo trimestre. Ejercicio del gasto FONE</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2023/2T_2023 AGS Ejercicio del gasto FONE.xlsx" target="_blank">
                          <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Tercer trimestre. Ejercicio del gasto FONE</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2023/3T_2023 AGS Ejercicio del gasto FONE.xlsx" target="_blank">
                          <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Cuarto trimestre. Ejercicio del gasto FONE</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2023/4T_2023 AGS Ejercicio del gasto FONE.xlsx" target="_blank">
                          <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Informe definitivo. Ejercicio del gasto FONE</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2023/ID_2023 AGS Ejercicio del gasto FONE.xlsx" target="_blank">
                          <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>Indicadores</td>
              <td colspan="2">
                <table class="table table-striped table-hover">
                  <tbody>
                    <tr>
                      <td>Cuarto trimestre. Indicadores FONE</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2023/4T_2023 Indicadores FONE.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Cuarto trimestre definitivo. Indicadores FONE</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2023/4TD_2023 Indicadores FONE.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Ejercicio Fiscal 2022</h2>
        <table class="table">
          <thead>
            <tr>
              <th>Tipo</th>
              <th>Informe</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Periódico Oficial del Estado</td>
              <td colspan="2">
                <table class="table table-striped table-hover">
                  <tbody>
                    <tr>
                      <td>Primer trimestre</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2022/1T_2022 POE.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Segundo trimestre</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2022/2T_2022 POE.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Tercer trimestre</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2022/3T_2022 POE.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Cuarto trimestre</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2022/4T_2022 POE.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Informe definitivo</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2022/ID_2022 POE.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>Ejercicio del gasto</td>
              <td colspan="2">
                <table class="table table-striped table-hover">
                  <tbody>
                    <tr>
                      <td>Primer trimestre. Ejercicio del gasto FONE</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2022/1T_2022 AGS Ejercicio del gasto FONE.xlsx" target="_blank">
                          <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Segundo trimestre. Ejercicio del gasto FONE</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2022/2T_2022 AGS Ejercicio del gasto FONE.xlsx" target="_blank">
                          <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Tercer trimestre. Ejercicio del gasto FONE</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2022/3T_2022 AGS Ejercicio del gasto FONE.xlsx" target="_blank">
                          <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Cuarto trimestre. Ejercicio del gasto FONE</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2022/4T_2022 AGS Ejercicio del gasto FONE.xlsx" target="_blank">
                          <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Informe definitivo. Ejercicio del gasto FONE</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2022/ID_2022 AGS Ejercicio del gasto FONE.xlsx" target="_blank">
                          <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>Indicadores</td>
              <td colspan="2">
                <table class="table table-striped table-hover">
                  <tbody>
                    <tr>
                      <td>Cuarto trimestre. Indicadores FONE</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2022/4T_2022 Indicadores FONE.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Cuarto trimestre definitivo. Indicadores FONE</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2022/4TD_2022 Indicadores FONE.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Ejercicio Fiscal 2021</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Informe</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Primer trimestre. Ejercicio del gasto FONE</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2021/T1_2021 AGS Ejercicio del gasto FONE.xlsx" target="_blank">
                  <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>Segundo trimestre. Ejercicio del gasto FONE</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2021/T2_2021 AGS Ejercicio del gasto FONE.xlsx" target="_blank">
                  <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>Tercer trimestre. Ejercicio del gasto FONE</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2021/T3_2021 AGS Ejercicio del gasto FONE.xlsx" target="_blank">
                  <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>Cuarto trimestre. Ejercicio del gasto FONE</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2021/T4_2021 AGS Ejercicio del gasto FONE.xlsx" target="_blank">
                  <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>Indicador definitivo. Ejercicio del gasto FONE</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2021/ID_2021 AGS Ejercicio del Gasto FONE.xlsx" target="_blank">
                  <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Ejercicio Fiscal 2020</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Informe</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Primer trimestre. Ejercicio del gasto FONE</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2020/T1_2020 AGS Ejercicio del gasto FONE.xlsx" target="_blank">
                  <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>Segundo trimestre. Ejercicio del gasto FONE</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2020/T2_2020 AGS Ejercicio del gasto FONE.xlsx" target="_blank">
                  <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>Tercer trimestre. Ejercicio del gasto FONE</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2020/T3_2020 AGS Ejercicio del gasto FONE.xlsx" target="_blank">
                  <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>Cuarto trimestre. Ejercicio del gasto FONE</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2020/T4_2020 AGS Ejercicio del gasto FONE.xlsx" target="_blank">
                  <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>Indicador definitivo. Ejercicio del gasto FONE</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2020/ID_2020 AGS Ejercicio del gasto FONE.xlsx" target="_blank">
                  <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Ejercicio Fiscal 2019</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Informe</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Primer trimestre. Ejercicio del gasto FONE</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2019/T1_2019 AGS Ejercicio del gasto FONE.xlsx" target="_blank">
                  <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>Segundo trimestre. Ejercicio del gasto FONE</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2019/T2_2019 AGS Ejercicio del gasto FONE.xlsx" target="_blank">
                  <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>Tercer trimestre. Ejercicio del gasto FONE</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2019/T3_2019 AGS Ejercicio del gasto FONE.xlsx" target="_blank">
                  <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>Cuarto trimestre. Ejercicio del gasto FONE</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2019/T4_2019 AGS Ejercicio del gasto FONE.xlsx" target="_blank">
                  <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>Indicador definitivo. Ejercicio del gasto FONE</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2019/ID_2019 AGS Ejercicio del gasto FONE.xlsx" target="_blank">
                  <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Ejercicio Fiscal 2018</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Informe</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Tercer trimestre. Ejercicio del gasto FONE</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2018/T3_2018 AGS Ejercicio del gasto FONE.xlsx" target="_blank">
                  <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>Cuarto trimestre. Ejercicio del gasto FONE</td>
              <td class="text-center" width="50">
                <a href="https://www.iea.gob.mx/IEA/transparencia/SRFT/2018/T4_2018 AGS Ejercicio del gasto FONE.xlsx" target="_blank">
                  <span class="iconify" data-icon="mdi:microsoft-excel" width="35" height="35" color="green"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'Luis Gabriel Lozano Collazo',
          area: 'Programación, presupuesto e infraestructura educativa del IEA',
          telefono: '449 910 5600 ext. 4111',
          fecha: '05/09/2024'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "InformesSRFTView",
  components: {
    RIComponent,
  }
}
</script>

<style lang="scss" scoped>

</style>

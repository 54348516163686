<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="titulo">IEA - Evaluaciones Externas</h1>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Fondo de Aportaciones para la Nómina Educativa (FONE)</h2>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Ejercicio Fiscal 2022</h2>
        <table class="table">
          <thead>
            <tr>
              <th>Tipo</th>
              <th>Informe</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Consistencia y Resultados</td>
              <td colspan="2">
                <table class="table table-striped table-hover">
                  <tbody>
                    <tr>
                      <td>Formato para la Difusión de los Resultados de las Evaluaciones</td>
                      <td class="text-center" width="50">
                        <a href="https://www.iea.gob.mx/IEA/transparencia/evaluaciones-externas/2022/Formato para la Difusión de los Resultados de las Evaluaciones.pdf" target="_blank">
                          <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'José Luis García García',
          area: 'Subdirección de Información, Planeación Estratégica y Evaluación Institucional',
          telefono: '449 910 5600 ext. 4098',
          fecha: '09/07/2024'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "InformesSRFTView",
  components: {
    RIComponent,
  }
}
</script>

<style lang="scss" scoped>

</style>

<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="titulo">IEA - Avisos de Privacidad</h1>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Aviso de privacidad</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(file, index) of avisosDePrivacidad">
              <td>{{ index + 1 }}</td>
              <td>Aviso de Privacidad Integral de {{ file.name }}</td>
              <td class="text-center" width="50">
                <a :href="file.path" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent
          :responsable="{
            nombre: 'Marisol Maldonado Romero',
            area: 'Dirección De Gestión Institucional',
            telefono: '449 910 5694 ext. 4244',
            fecha: '9/11/2023'
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RIComponent from '@/components/RIComponent.vue';

import avisosDePrivacidadData from '@/assets/json/avisosDePrivacidadData.json';

export default {
  name: "AvisosDePrivacidadView",
  components: {
    RIComponent
  },
  data: () => {
    return {
      avisosDePrivacidad: avisosDePrivacidadData
    }
  }
}
</script>

<style>

</style>

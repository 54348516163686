<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="titulo">IEA - Sistema de Control Interno Institucional</h1>
      </div>
    </div>

    <div class="row" v-for="directory of scii">
      <div class="col-12">
        <h2 class="subtitulo">Control Interno {{ directory.name }}</h2>
      </div>
      <div class="col-12">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Descripción</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(file, index) of directory.contents">
              <td>{{ index + 1 }}</td>
              <td>{{ file.name }}</td>
              <td class="text-center" width="50">
                <a :href="file.path" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'Alejandra Ortiz Serna',
          area: 'Departamento de Desarrollo Institucional',
          telefono: '449 910 5600 ext. 4185',
          fecha: '09/09/2024'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from '@/components/RIComponent.vue';
import sciiData from '@/assets/json/sciiData.json';

export default {
  name: "SciiView",
  data() {
    return {
      scii: sciiData.reverse()
    }
  },
  components: {
    RIComponent
  }
}
</script>

<style lang="scss" scoped>

</style>
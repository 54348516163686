<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA - Código de Ética y Conducta</h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2 class="subtitulo">Código de Ética y Conducta</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Descripción</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>CÓDIGO DE ÉTICA DEL INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES</td>
              <td class="text-center" width="50">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-4-68.pdf" target="_blank" rel="noopener noreferrer">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>REFORMAS A LOS “LINEAMIENTOS GENERALES PARA ESTABLECER LAS BASES DE LA INTEGRACIÓN, ORGANIZACIÓN, ATRIBUCIONES Y FUNCIO NAMIENTO DEL COMITÉ DE ÉTICA DEL INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES”</td>
              <td class="text-center" width="50">
                <a href="https://eservicios2.aguascalientes.gob.mx/PeriodicoOficial/web/viewer.html?file=../Archivos/10012.pdf#page=32" target="_blank" rel="noopener noreferrer">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>LINEAMIENTOS GENERALES PARA ESTABLECER LAS BASES DE LA INTEGRACIÓN, ORGANIZACIÓN, ATRIBUCIONES Y FUNCIONAMIENTO DEL COMITÉ DE ÉTICA DEL INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES</td>
              <td class="text-center" width="50">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-19-59.pdf" target="_blank" rel="noopener noreferrer">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>CODIGO DE CONDUCTA DEL INSTITUTO DE EDUCACIÓN DE AGUASCALIENTES</td>
              <td class="text-center" width="50">
                <a href="https://eservicios2.aguascalientes.gob.mx/NormatecaAdministrador/archivos/EDO-4-97.pdf" target="_blank" rel="noopener noreferrer">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent :responsable="{
          nombre: 'José Martín Salazar Sánchez',
          area: 'Departamento de Normatividad y Consulta',
          telefono: '449 910 5600 ext. 4147',
          fecha: '29/04/2024'
        }" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "CodigoEticaYConductaView",
  components: {
    RIComponent,
  }
}
</script>

<style lang="scss" scoped>

</style>
<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="titulo">IEA - Indicadores Fondo de Aportaciones para la Nómina Educativa y Convenios Federales</h1>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Indicadores FONE</h2>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Periodo 2021</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Informe</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Segundo trimestre</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/FONE/2021/FONE_2do_TRIM_2021.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>Cuarto trimestre</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/FONE/2021/FONE_4to_TRIM_2021.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>Cuarto trimestre (Definitivo)</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/FONE/2021/FONE_4to_TRIM_DEF_2021.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Periodo 2020</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Informe</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Segundo trimestre</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/FONE/2020/FONE_2do_TRIM_2020.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>Cuarto trimestre</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/FONE/2020/FONE_4to_TRIM_2020.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>Cuarto trimestre (Definitivo)</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/FONE/2020/FONE_4to_TRIM_DEF_2020.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Periodo 2019</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Informe</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Primer trimestre</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/FONE/2019/FONE_1er_TRIM_2019.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>Segundo trimestre</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/FONE/2019/FONE_2do_TRIM_2019.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>Cuarto trimestre</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/FONE/2019/FONE_4to_TRIM_2019.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
             <tr>
              <td>4</td>
              <td>Cuarto trimestre (Definitivo)</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/FONE/2019/FONE_4to_TRIM_DEF_2019.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Periodo 2018</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Informe</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Primer trimestre</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/FONE/2018/FONE_1er_TRIM_2018.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>Segundo trimestre</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/FONE/2018/FONE_2do_TRIM_2018.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>Cuarto trimestre</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/FONE/2018/FONE_4to_TRIM_2018.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>Cuarto trimestre (Definitivo)</td>
              <td class="text-center" width="50">
                <a href="" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Periodo 2017</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Informe</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Primer trimestre</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/FONE/2017/FONE_1er_TRIM_2017.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>Segundo trimestre</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/FONE/2017/FONE_2do_TRIM_2017.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>Cuarto trimestre</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/FONE/2017/FONE_4to_TRIM_2017.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
             <tr>
              <td>4</td>
              <td>Cuarto trimestre (Definitivo)</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/FONE/2017/FONE_4to_TRIM_DEF_2017.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Periodo 2016</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Informe</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Primer trimestre</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/FONE/2016/FONE_1er_TRIM_2016.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>Segundo trimestre</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/FONE/2016/FONE_2do_TRIM_2016.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>Cuarto trimestre</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/FONE/2016/FONE_4to_TRIM_2016.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
             <tr>
              <td>4</td>
              <td>Cuarto trimestre (Definitivo)</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/FONE/2016/FONE_4to_TRIM_DEF_2016.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Convenios Federales</h2>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Periodo 2016</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Descripción</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Convenios federales 2016</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/convenios-federales/2016/convenios_federales2016.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>Ejercicio y gasto de programas federales 2016 T01</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/convenios-federales/2016/Ejercicio y gasto de programas federales 2016T01.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>Ejercicio y gasto de programas federales 2016 T02</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/convenios-federales/2016/Ejercicio y gasto de programas federales 2016T02.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>Programas federales recibidos 2016 T01</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/convenios-federales/2016/Programas federales recibidos 2016T01.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>Programas federales recibidos 2016 T02</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/convenios-federales/2016/Programas federales recibidos 2016T02.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>6</td>
              <td>Programas federales recibidos 2016 T04</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/convenios-federales/2016/Programas federales recibidos 2016T04.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h2 class="subtitulo">Periodo 2015</h2>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Descripción</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Convenios federales 2015</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/convenios-federales/2015/convenios_federales2015.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>Ejercicio y gasto de programas federales 2015 T01</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/convenios-federales/2015/Ejercicio y gasto de programas federales 2015T01.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>Ejercicio y gasto de programas federales 2015 T02</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/convenios-federales/2015/Ejercicio y gasto de programas federales 2015T02.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>Ejercicio y gasto de programas federales 2015 T03</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/convenios-federales/2015/Ejercicio y gasto de programas federales 2015T03.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>5</td>
              <td>Ejercicio y gasto de programas federales 2015 T04</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/convenios-federales/2015/Ejercicio y gasto de programas federales 2015T04.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>6</td>
              <td>Programas federales recibidos 2015 T01</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/convenios-federales/2015/Programas federales recibidos 2015T01.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>7</td>
              <td>Programas federales recibidos 2015 T02</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/convenios-federales/2015/Programas federales recibidos 2015T02.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>8</td>
              <td>Programas federales recibidos 2015 T03</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/convenios-federales/2015/Programas federales recibidos 2015T03.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>9</td>
              <td>Programas federales recibidos 2015 T04</td>
              <td class="text-center" width="50">
                <a href="IEA/transparencia/convenios-federales/2015/programas federales recibidos 2015T04.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent
          :responsable="{
            nombre: 'José Luis García García',
            area: 'Departamento de Estadística',
            telefono: '449 910 5600 ext. 4522',
            fecha: '19/10/2023'
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "IndicadoresFONEyConveniosFederalesView",
  components: {
    RIComponent
  }
}
</script>

<style>

</style>

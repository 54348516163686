<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1 class="titulo">IEA - Manuales</h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>No.</th>
              <th>Descripción</th>
              <th class="text-end">Enlace</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>MANUAL DE ORGANIZACIÓN</td>
              <td class="text-center" width="50">
                <a href="IEA/scii/manuales/MANUAL DE ORGANIZACIÓN.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>MANUAL DE PROCEDIMIENTOS</td>
              <td class="text-center" width="50">
                <a href="IEA/scii/manuales/MANUAL DE PROCEDIMIENTOS.pdf" target="_blank">
                  <span class="iconify" data-icon="mdi:file-pdf-box" width="35" height="35" color="red"></span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RIComponent
          :responsable="{
            nombre: 'Alejandra Ortiz Serna',
            area: 'Departamento de Desarrollo Institucional',
            telefono: '449 910 5600 ext. 4185',
            fecha: '1/12/2022'
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RIComponent from '@/components/RIComponent.vue';

export default {
  name: "ManualesView",
  components: {
    RIComponent
  }
}
</script>

<style>

</style>